import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { breakpoints, layout, colors } from '../../theme'
import CheckboxList, { StyledCheckboxList } from '../../components/CheckboxList'
import CheckboxTile from '../../components/CheckboxTile'
import { Button, Pulse } from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import { navigate } from '../../components/Link'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { CustomerContext } from '../../components/context/CustomerContext'
import { buildAskForQuoteBody, computeNextPageWithBaseUri } from '../../util'

const OptionsPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const [error, setError] = useState()

  const { customer } = useContext(CustomerContext)

  const {
    state: eventState,
    dispatch: dispatchEventCtx,
    categories,
  } = useContext(EventContext)

  const { state: checkoutState, dispatch: dispatchCheckoutCtx } = useContext(
    CheckoutContext,
  )

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const submit = async () => {
    const containsDrinks =
      eventState.options.filter(o => o.selected && o.value === 'drinks')
        .length > 0

    const nextUrl = computeNextPageWithBaseUri('demande', 'options', {
      isUserLoggedIn: !!customer,
      containsDrinks,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, {
        state: { next: true },
      })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        categories,
      })

      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', { state: { quote: true } })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: { demandSource: null },
        })
      }
    }
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledOptionsPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Quels services souhaitez-vous inclure à cette prestation\u00A0?`}
              subTitle={`En complément du budget fixé précédemment`}
            />
            <CheckboxList
              tiles={eventState.options}
              onTileSelected={(tile, tiles) => {
                dispatchEventCtx({
                  type: 'UPDATE_OPTIONS',
                  payload: { options: tiles },
                  analytics: { label: 'From_OptionsPage' },
                })
              }}
              renderTile={<CheckboxTile />}
            />

            <p className="error">{error}</p>

            <div className="btn-bar">
              <Button id="previous" onClick={() => navigate(-1)}>
                <div>&rarr;</div>
                <span>&nbsp;Précédent</span>
              </Button>
              <Button className="submit" id="next" onClick={submitWithDelay}>
                {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
              </Button>
            </div>
          </div>
        )}
      </DemandeLayout>
    </StyledOptionsPage>
  )
}

const StyledOptionsPage = styled.div`
  .page-content {
    max-width: 750px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledCheckboxList} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-left: -7px;
    margin-right: -7px;

    & > * {
      margin: 7px;
    }
  }

  .error {
    margin-bottom: 10px;
    color: ${colors.brand.dark2};
  }

  .error {
    margin-bottom: 10px;
    color: ${colors.brand.dark2};
  }
`

export default OptionsPage
export { StyledOptionsPage }
